import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { BrainCircuit, ArrowUp, ArrowDown, Minus, TrendingUp, TrendingDown, Activity, PercentIcon } from 'lucide-react';

function DynamicAdvice({ 
  price, 
  ma20, 
  ma50, 
  rsi6, 
  rsi12, 
  rsi24, 
  chopIndex, 
  prediction, 
  volatility, 
  predictionSuccessRate,
  confidenceInterval,
  trend,
  timeframe,
  chopIndexHigh = 60,
  chopIndexLow = 40,
  predictionChangeThreshold = 1,
  stopLossFactor = 0.05,
  takeProfitFactor = 0.10
}) {
  const formatNumber = (value, decimals = 2) => {
    return typeof value === 'number' && !isNaN(value) ? value.toFixed(decimals) : 'N/A';
  };

  const getRecommendation = () => {
    if (chopIndex === null || chopIndex === undefined) return "Données insuffisantes pour générer une recommandation.";
    if (chopIndex > chopIndexHigh) {
      return `Marché agité sur ${timeframe} jours. Considérez des stratégies de range trading.`;
    } else if (trend === "À la hausse") {
      return `Tendance haussière sur ${timeframe} jours. Considérez des positions longues avec des stops serrés sous le support.`;
    } else if (trend === "À la baisse") {
      return `Tendance baissière sur ${timeframe} jours. Envisagez des positions courtes avec des stops au-dessus de la résistance.`;
    } else {
      return `Tendance neutre sur ${timeframe} jours. Attendez une confirmation de tendance avant de prendre position.`;
    }
  };

  const recommendation = getRecommendation();

  const getEntryPoints = () => {
    if (!price || isNaN(price)) return [];
    if (chopIndex > chopIndexHigh) {
      return [
        { type: 'Achat', price: price * 0.98, date: 'Support de range' },
        { type: 'Vente', price: price * 1.02, date: 'Résistance de range' }
      ];
    } else if (trend === "À la hausse") {
      return [
        { type: 'Achat', price: price * 0.98, date: 'Proche du prix actuel' },
        { type: 'Achat', price: price * 1.02, date: 'Cassure Résistance' }
      ];
    } else if (trend === "À la baisse") {
      return [
        { type: 'Vente', price: price * 1.02, date: 'Proche du prix actuel' },
        { type: 'Vente', price: price * 0.98, date: 'Cassure Support' }
      ];
    } else {
      return [];
    }
  };

  const entryPoints = getEntryPoints();

  const stopLoss = price && volatility && !isNaN(volatility)
    ? price * (1 - stopLossFactor * (1 + volatility))
    : null;

  const takeProfit = price && volatility && !isNaN(volatility)
    ? price * (1 + takeProfitFactor * (1 + volatility))
    : null;

  const getTrendIcon = () => {
    if (trend === "À la hausse") return <ArrowUp className="text-green-500" />;
    if (trend === "À la baisse") return <ArrowDown className="text-red-500" />;
    return <Minus className="text-yellow-500" />;
  };

  const getPredictionContext = () => {
    if (!prediction || !price || isNaN(prediction) || isNaN(price)) return "stable";
    const difference = prediction - price;
    const percentChange = (difference / price) * 100;
    
    if (Math.abs(percentChange) < predictionChangeThreshold) return "stable";
    return percentChange > 0 ? "bullish" : "bearish";
  };

  const predictionContext = getPredictionContext();

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle className="flex items-center">
          <BrainCircuit className="mr-2" /> Plan de Trading IA ({timeframe} jours)
          <span className="ml-auto">{getTrendIcon()}</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="font-medium mb-4">{recommendation}</p>
        <div className="space-y-4">
          <section>
            <h4 className="font-semibold mb-2">Points d'entrée :</h4>
            {entryPoints.length > 0 ? (
              <ul className="space-y-2">
                {entryPoints.map((entry, index) => (
                  <li key={index} className={`p-2 rounded flex justify-between ${entry.type === 'Achat' ? 'bg-green-100' : 'bg-red-100'}`}>
                    <span><strong>{entry.type}:</strong> {formatNumber(entry.price)}$</span>
                    <span className="text-sm text-gray-600">{entry.date}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Pas de points d'entrée recommandés actuellement.</p>
            )}
          </section>
          <section>
            <h4 className="font-semibold mb-2">Gestion du risque :</h4>
            <ul className="space-y-2">
              <li className="flex justify-between"><span><strong>Stop-Loss:</strong></span> <span>{formatNumber(stopLoss)}$</span></li>
              <li className="flex justify-between"><span><strong>Take-Profit:</strong></span> <span>{formatNumber(takeProfit)}$</span></li>
              <li className="flex justify-between"><span><strong>Volatilité:</strong></span> <span>{volatility ? formatNumber(volatility * 100) : 'N/A'}%</span></li>
            </ul>
          </section>
          <section>
            <h4 className="font-semibold mb-2">Prédiction ML ({timeframe} jours) :</h4>
            <div className={`p-3 rounded flex items-center justify-between ${
              predictionContext === 'bullish' ? 'bg-green-100' : 
              predictionContext === 'bearish' ? 'bg-red-100' : 'bg-yellow-100'
            }`}>
              <span>Prix prédit : {formatNumber(prediction)}$</span>
              <span className="flex items-center">
                {predictionContext === 'bullish' ? <TrendingUp className="text-green-500 mr-1" /> :
                 predictionContext === 'bearish' ? <TrendingDown className="text-red-500 mr-1" /> :
                 <Minus className="text-yellow-500 mr-1" />}
                {prediction && price ? formatNumber(((prediction - price) / price * 100)) : 'N/A'}%
              </span>
            </div>
            <p className="mt-2 text-sm">
              {predictionContext === 'bullish' ? "La prédiction suggère une tendance haussière." :
               predictionContext === 'bearish' ? "La prédiction suggère une tendance baissière." :
               "La prédiction suggère une stabilité des prix."}
              <br />Taux de réussite des prédictions : {predictionSuccessRate ? formatNumber(predictionSuccessRate * 100) : 'N/A'}%
            </p>
            {confidenceInterval && Array.isArray(confidenceInterval) && confidenceInterval.length === 2 && (
              <p className="mt-2 text-sm flex items-center">
                <PercentIcon className="mr-1" />
                Intervalle de confiance : {formatNumber(confidenceInterval[0])}$ - {formatNumber(confidenceInterval[1])}$
              </p>
            )}
          </section>
          <section>
            <h4 className="font-semibold mb-2">Tendance du marché ({timeframe} jours) :</h4>
            <p className="flex items-center">
              {getTrendIcon()}
              <span className="ml-2">{trend || 'Indéterminée'}</span>
            </p>
          </section>
          <section>
            <h4 className="font-semibold mb-2">Indicateurs techniques :</h4>
            <ul className="space-y-2 text-sm">
              <li><strong>RSI (6/12/24):</strong> {formatNumber(rsi6)} / {formatNumber(rsi12)} / {formatNumber(rsi24)}</li>
              <li><strong>MA20/MA50:</strong> {formatNumber(ma20)} / {formatNumber(ma50)}</li>
              <li className="flex items-center">
                <strong>Chop Index:</strong> {formatNumber(chopIndex)}
                {chopIndex !== null && (
                  <>
                    <Activity className={`ml-2 ${chopIndex > chopIndexHigh ? 'text-red-500' : chopIndex < chopIndexLow ? 'text-green-500' : 'text-yellow-500'}`} />
                    <span className="ml-2 text-xs text-gray-500">(Indique la tendance du marché)</span>
                  </>
                )}
              </li>
            </ul>
          </section>
        </div>
      </CardContent>
    </Card>
  );
}

DynamicAdvice.propTypes = {
  price: PropTypes.number,
  ma20: PropTypes.number,
  ma50: PropTypes.number,
  rsi6: PropTypes.number,
  rsi12: PropTypes.number,
  rsi24: PropTypes.number,
  chopIndex: PropTypes.number,
  prediction: PropTypes.number,
  volatility: PropTypes.number,
  predictionSuccessRate: PropTypes.number,
  confidenceInterval: PropTypes.arrayOf(PropTypes.number),
  trend: PropTypes.string,
  timeframe: PropTypes.number,
  chopIndexHigh: PropTypes.number,
  chopIndexLow: PropTypes.number,
  predictionChangeThreshold: PropTypes.number,
  stopLossFactor: PropTypes.number,
  takeProfitFactor: PropTypes.number
};

export default DynamicAdvice;