import React, { useState, useEffect } from 'react';
import { useDataFetching } from '../useDataFetching';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";

const cryptoOptions = [
  { value: 'bitcoin', label: 'Bitcoin' },
  { value: 'ethereum', label: 'Ethereum' },
  { value: 'solana', label: 'Solana' },
  { value: 'cosmos', label: 'ATOM' },
  { value: 'zelcash', label: 'Flux' },
];

const timeframeOptions = [
  { value: 1, label: '1 jour' },
  { value: 7, label: '7 jours' },
  { value: 30, label: '30 jours' },
  { value: 365, label: '1 an' },
];

const ITEMS_PER_PAGE = 10;

function HistoryPage() {
  const navigate = useNavigate();
  const [selectedCrypto, setSelectedCrypto] = useState('bitcoin');
  const [selectedTimeframe, setSelectedTimeframe] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('all');
  const { data: cryptoData, isLoading, error, fetchData } = useDataFetching(selectedCrypto, selectedTimeframe);

  useEffect(() => {
    fetchData(selectedCrypto, selectedTimeframe);
  }, [selectedCrypto, selectedTimeframe, fetchData]);

  if (isLoading) {
    return <div className="text-center p-4">Chargement des données...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">Erreur lors du chargement des données : {error.message}</div>;
  }

  const predictionHistory = cryptoData?.predictionHistory || [];
  const predictionSuccessRate = cryptoData?.predictionSuccessRate || 0;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status) => {
    if (status === null) return 'text-yellow-500';
    return status ? 'text-green-500' : 'text-red-500';
  };

  // Trier et filtrer les prédictions
  const filteredAndSortedPredictions = predictionHistory
    .filter(prediction => {
      if (filter === 'success') return prediction.isSuccess === true;
      if (filter === 'failure') return prediction.isSuccess === false;
      return true;
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  // Pagination
  const totalPages = Math.ceil(filteredAndSortedPredictions.length / ITEMS_PER_PAGE);
  const paginatedPredictions = filteredAndSortedPredictions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Préparation des données pour l'export CSV
  const csvData = filteredAndSortedPredictions.map(prediction => ({
    Date: formatDate(prediction.date),
    Crypto: selectedCrypto,
    Prediction: prediction.predictedPrice ? `$${prediction.predictedPrice.toFixed(2)}` : 'N/A',
    'Prix Réel': prediction.actualPrice ? `$${prediction.actualPrice.toFixed(2)}` : 'N/A',
    Statut: prediction.isSuccess === null ? 'En attente' : prediction.isSuccess ? 'Réussie' : 'Échouée',
    Volatilité: prediction.volatility ? `${(prediction.volatility * 100).toFixed(2)}%` : 'N/A',
    'Intervalle de Confiance': prediction.confidenceInterval && Array.isArray(prediction.confidenceInterval) && prediction.confidenceInterval.length === 2 ?
      `$${prediction.confidenceInterval[0].toFixed(2)} - $${prediction.confidenceInterval[1].toFixed(2)}` : 'N/A',
    Tendance: prediction.trend || 'N/A'
  }));

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl sm:text-2xl font-bold mb-4">Historique des Prédictions</h1>

      <div className="mb-4 flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <label htmlFor="crypto-select" className="mr-2 mb-1 sm:mb-0">Crypto-monnaie:</label>
          <select
            id="crypto-select"
            value={selectedCrypto}
            onChange={(e) => setSelectedCrypto(e.target.value)}
            className="p-2 border rounded w-full sm:w-auto"
          >
            {cryptoOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center">
          <label htmlFor="timeframe-select" className="mr-2 mb-1 sm:mb-0">Période:</label>
          <select
            id="timeframe-select"
            value={selectedTimeframe}
            onChange={(e) => setSelectedTimeframe(Number(e.target.value))}
            className="p-2 border rounded w-full sm:w-auto"
          >
            {timeframeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center">
          <label htmlFor="filter-select" className="mr-2 mb-1 sm:mb-0">Filtre:</label>
          <select
            id="filter-select"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="p-2 border rounded w-full sm:w-auto"
          >
            <option value="all">Toutes les prédictions</option>
            <option value="success">Prédictions réussies</option>
            <option value="failure">Prédictions échouées</option>
          </select>
        </div>
      </div>

      <div className="mb-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 w-full sm:w-auto"
        >
          Retour
        </button>
        <CSVLink
          data={csvData}
          filename={"prediction_history.csv"}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 w-full sm:w-auto text-center"
        >
          Exporter en CSV
        </CSVLink>
      </div>

      <div className="mt-4">
        <p className="font-bold text-sm sm:text-base">Taux de réussite des prédictions: {(predictionSuccessRate * 100).toFixed(2)}%</p>
      </div>

      {paginatedPredictions.length === 0 ? (
        <p className="mt-4 text-sm sm:text-base">Aucun historique de prédiction disponible pour {selectedCrypto} sur {selectedTimeframe} jours.</p>
      ) : (
        <>
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full table-auto border text-xs sm:text-sm">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-2 sm:px-4 py-2 text-left border">Date</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Crypto</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Prédiction</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Prix Réel</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Statut</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Volatilité</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Intervalle de Confiance</th>
                  <th className="px-2 sm:px-4 py-2 text-left border">Tendance</th>
                </tr>
              </thead>
              <tbody>
                {paginatedPredictions.map((prediction, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                    <td className="border px-2 sm:px-4 py-2">{formatDate(prediction.date)}</td>
                    <td className="border px-2 sm:px-4 py-2">{selectedCrypto}</td>
                    <td className="border px-2 sm:px-4 py-2">${prediction.predictedPrice ? prediction.predictedPrice.toFixed(2) : 'N/A'}</td>
                    <td className="border px-2 sm:px-4 py-2">{prediction.actualPrice ? `$${prediction.actualPrice.toFixed(2)}` : 'N/A'}</td>
                    <td className={`border px-2 sm:px-4 py-2 ${getStatusColor(prediction.isSuccess)}`}>
                      {prediction.isSuccess === null ? 'En attente' : prediction.isSuccess ? 'Réussie' : 'Échouée'}
                    </td>
                    <td className="border px-2 sm:px-4 py-2">{prediction.volatility ? `${(prediction.volatility * 100).toFixed(2)}%` : 'N/A'}</td>
                    <td className="border px-2 sm:px-4 py-2">
                      {prediction.confidenceInterval && Array.isArray(prediction.confidenceInterval) && prediction.confidenceInterval.length === 2 ? 
                        `$${prediction.confidenceInterval[0].toFixed(2)} - $${prediction.confidenceInterval[1].toFixed(2)}` : 
                        'N/A'}
                    </td>
                    <td className="border px-2 sm:px-4 py-2">{prediction.trend || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-center items-center text-sm sm:text-base">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-2 sm:px-4 py-1 sm:py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300 mr-2"
            >
              Précédent
            </button>
            <span className="px-2 sm:px-4 py-1 sm:py-2">
              Page {currentPage} sur {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-2 sm:px-4 py-1 sm:py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300 ml-2"
            >
              Suivant
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default HistoryPage;