import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

const CACHE_DURATION = 60 * 60 * 1000; // 1 heure en millisecondes

function useDataFetching(initialCrypto, initialTimeframe) {
  const [cache, setCache] = useState({});
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async (crypto, timeframe) => {
    const cacheKey = `${crypto}_${timeframe}`;
    const cachedData = cache[cacheKey];

    if (cachedData && (Date.now() - cachedData.timestamp < CACHE_DURATION)) {
      console.log(`Using cached data for ${crypto} with timeframe ${timeframe}`);
      setData(cachedData.data);
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      console.log(`Fetching new data for ${crypto} with timeframe ${timeframe}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/crypto/${crypto}/${timeframe}`);
      const newData = response.data;
      
      console.log(`Received data for ${crypto}:`, newData);
      
      if (!newData || typeof newData !== 'object') {
        throw new Error('Invalid data received from the server');
      }
      
      // Vérification des nouvelles données
      if (!newData.data || !newData.data.prices || !newData.data.rsi24 || !newData.data.chopIndex || !newData.data.volatility) {
        console.warn('Some expected data is missing:', newData);
      }

      // Vérification des données de prédiction
      if (!newData.predictionHistory || !Array.isArray(newData.predictionHistory)) {
        console.warn('Prediction history is missing or invalid:', newData);
      }

      // Vérification du taux de réussite des prédictions
      if (typeof newData.predictionSuccessRate !== 'number') {
        console.warn('Prediction success rate is missing or invalid:', newData);
      }

      setCache(prevCache => ({
        ...prevCache,
        [cacheKey]: { data: newData, timestamp: Date.now() }
      }));
      setData(newData);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (cachedData) {
        console.log(`Using last known data for ${crypto} with timeframe ${timeframe} due to error`);
        setData(cachedData.data);
      } else {
        setError({
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [cache]);

  useEffect(() => {
    fetchData(initialCrypto, initialTimeframe);
  }, [initialCrypto, initialTimeframe, fetchData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      Object.entries(cache).forEach(([key, value]) => {
        if (Date.now() - value.timestamp >= CACHE_DURATION) {
          const [crypto, timeframe] = key.split('_');
          console.log(`Cache expired for ${crypto} with timeframe ${timeframe}. Fetching new data.`);
          fetchData(crypto, timeframe);
        }
      });
    }, 60000); // Vérifier toutes les minutes

    return () => clearInterval(intervalId);
  }, [cache, fetchData]);

  return { data, isLoading, error, fetchData };
}

export { useDataFetching }; // Exporte le hook