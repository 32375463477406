import React, { useEffect, useState } from 'react';
import { useDataFetching } from './useDataFetching';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import { FaRegCheckSquare } from 'react-icons/fa';
import Alerts from './components/Alerts';
import DynamicAdvice from './components/DynamicAdvice';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; 
import HistoryPage from './components/HistoryPage';
import './index.css';
import 'chartjs-adapter-date-fns';
import { fr } from 'date-fns/locale';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  TimeScale
);

const timeframes = [
  { label: '1 jour', value: 1 },
  { label: '7 jours', value: 7 },
  { label: '30 jours', value: 30 },
  { label: '1 an', value: 365 },
];

const fibonacciLevels = [
  { level: 0, color: 'rgba(75, 192, 192, 1)' },
  { level: 0.236, color: 'rgba(255, 99, 132, 1)' },
  { level: 0.382, color: 'rgba(255, 206, 86, 1)' },
  { level: 0.5, color: 'rgba(54, 162, 235, 1)' },
  { level: 0.618, color: 'rgba(153, 102, 255, 1)' },
  { level: 0.764, color: 'rgba(255, 159, 64, 1)' },
  { level: 1, color: 'rgba(75, 192, 192, 0.5)' },
];

function App() {
  const [selectedCrypto, setSelectedCrypto] = useState('bitcoin');
  const [selectedTimeframe, setSelectedTimeframe] = useState(1);
  const { data: cryptoData, isLoading, error, fetchData } = useDataFetching(selectedCrypto, selectedTimeframe);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [showSupport, setShowSupport] = useState(true);
  const [showResistance, setShowResistance] = useState(true);
  const [showMidRange, setShowMidRange] = useState(true);
  const [showControls, setShowControls] = useState(false);
  //const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const [previousVolume, setPreviousVolume] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showMLPrediction, setShowMLPrediction] = useState(true);
  const [predictionSuccessRate, setPredictionSuccessRate] = useState(0);
  const [volatility, setVolatility] = useState(null);
  const [confidenceInterval, setConfidenceInterval] = useState(null);
  const [trend, setTrend] = useState(null);

  useEffect(() => {
    if (cryptoData && cryptoData.data && cryptoData.data.total_volumes) {
      setPreviousVolume(cryptoData.data.total_volumes[cryptoData.data.total_volumes.length - 1][1]);
    }
  }, [cryptoData]);

  useEffect(() => {
    if (cryptoData) {
      console.log(`Received data for ${selectedCrypto}:`, cryptoData);
      console.log('Prediction History:', cryptoData.predictionHistory);
      setPredictionSuccessRate(cryptoData.predictionSuccessRate || 0);
      setVolatility(cryptoData.volatility || null);
      setConfidenceInterval(cryptoData.confidenceInterval || null);
      setTrend(cryptoData.trend || null);
    }
  }, [cryptoData, selectedCrypto]);

  // ... (suite du composant App)

const handleChartClick = (event, elements) => {
  if (elements && elements.length > 0 && cryptoData && cryptoData.data && cryptoData.data.prices) {
    const chartElement = elements[0];
    const dataIndex = chartElement.index;
    if (dataIndex !== undefined && dataIndex < cryptoData.data.prices.length) {
      const price = cryptoData.data.prices[dataIndex][1];
      setSelectedPoints(prevPoints => {
        const newPoints = [...prevPoints, price];
        if (newPoints.length > 2) newPoints.shift();
        return newPoints;
      });
    }
  }
};

const handleClearSelection = () => {
  setSelectedPoints([]);
};

const calculateFibonacciLines = () => {
  if (selectedPoints.length < 2) return {};
  const [min, max] = selectedPoints.sort((a, b) => a - b);
  const diff = max - min;

  return fibonacciLevels.reduce((acc, { level, color }) => {
    acc[`fib${level}`] = {
      type: 'line',
      yMin: max - (diff * level),
      yMax: max - (diff * level),
      borderColor: color,
      borderWidth: 2,
      label: {
        content: `Fib ${Math.round(level * 100)}% - $${(max - diff * level).toFixed(2)}`,
        enabled: true,
        position: 'left',
      },
    };
    return acc;
  }, {});
};

const calculateSupportResistance = (prices) => {
  if (!Array.isArray(prices)) return { support: 0, resistance: 0, midRange: 0 };

  const sortedPrices = [...prices].sort((a, b) => a[1] - b[1]);
  const support = sortedPrices[Math.floor(sortedPrices.length * 0.1)][1];
  const resistance = sortedPrices[Math.floor(sortedPrices.length * 0.9)][1];
  const midRange = (support + resistance) / 2;

  return { support, resistance, midRange };
};

if (isLoading) {
  return <div className="flex justify-center items-center h-screen">Chargement des données...</div>;
}

if (error) {
  console.error('Error details:', error);
  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-2">Une erreur est survenue :</h2>
      <p className="text-red-500">{error.message}</p>
      {error.response && (
        <div className="mt-4">
          <p>Status : {error.status}</p>
          <pre className="bg-gray-100 p-2 mt-2 overflow-x-auto">
            {JSON.stringify(error.response, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}

if (!cryptoData || !cryptoData.data || !cryptoData.data.prices || !Array.isArray(cryptoData.data.prices)) {
  console.log('CryptoData is null or undefined, or prices are missing or not an array');
  return <div className="text-center p-4">Données non disponibles. Veuillez réessayer.</div>;
}

const { support, resistance, midRange } = calculateSupportResistance(cryptoData.data.prices);

const orderedPrices = [...cryptoData.data.prices].sort((a, b) => a[0] - b[0]);

const filteredPredictions = cryptoData.predictionHistory ? cryptoData.predictionHistory.filter(p => {
  const predDate = new Date(p.date);
  return predDate >= new Date(orderedPrices[0][0]) && predDate <= new Date(orderedPrices[orderedPrices.length - 1][0]);
}) : [];

console.log('Filtered Predictions:', filteredPredictions);
const allData = [
  ...orderedPrices.map(price => ({ date: price[0], value: price[1], type: 'price' })),
  ...filteredPredictions.map(p => ({ date: new Date(p.date).getTime(), value: p.predictedPrice, type: 'prediction' }))
];

const sortedData = allData.sort((a, b) => a.date - b.date);

const labels = sortedData.map(item => new Date(item.date));

const chartData = {
  labels: labels,
  datasets: [
    {
      label: `Prix en USD (${selectedCrypto})`,
      data: sortedData.filter(item => item.type === 'price').map(item => ({ x: item.date, y: item.value })),
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      yAxisID: 'y',
    },
    {
      label: 'RSI (24)',
      data: cryptoData.data.rsi24.map((rsi, index) => ({ x: new Date(orderedPrices[index][0]), y: rsi })),
      borderColor: 'rgba(255,99,132,1)',
      yAxisID: 'y1',
    },
    {
      label: 'Chop Index',
      data: cryptoData.data.chopIndex.map((chop, index) => ({ x: new Date(orderedPrices[index][0]), y: chop })),
      borderColor: 'rgba(54, 162, 235, 1)',
      yAxisID: 'y1',
    },
    {
      label: 'Prédictions historiques',
      data: filteredPredictions.map(p => ({
        x: new Date(p.date),
        y: p.predictedPrice,
        actualPrice: p.actualPrice,
        isSuccess: p.isSuccess,
        volatility: p.volatility,
        confidenceInterval: p.confidenceInterval,
        trend: p.trend
      })),
      backgroundColor: (context) => {
        const prediction = context.raw;
        if (!prediction || prediction.isSuccess === null) {
          return 'rgba(200, 200, 200, 0.6)';
        }
        return prediction.isSuccess ? 'rgba(75, 192, 192, 0.6)' : 'rgba(255, 99, 132, 0.6)';
      },
      borderColor: 'rgba(255, 159, 64, 1)',
      pointStyle: 'triangle',
      pointRadius: 6,
      pointHoverRadius: 8,
      yAxisID: 'y'
    }
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'dd/MM/yy'
        }
      },
      adapters: {
        date: {
          locale: fr
        }
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        autoSkip: true,
        maxTicksLimit: 10,
        font: {
          size: 10
        }
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        text: 'Prix (USD)',
        font: {
          size: 12
        }
      },
      ticks: {
        callback: function(value, index, values) {
          return '$' + value.toLocaleString();
        },
        font: {
          size: 10
        }
      },
      beginAtZero: false
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      title: {
        display: true,
        text: 'RSI / Chop Index',
        font: {
          size: 12
        }
      },
      min: 0,
      max: 100,
      ticks: {
        callback: function(value, index, values) {
          return value.toFixed(0);
        },
        font: {
          size: 10
        }
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
      labels: {
        boxWidth: 10,
        padding: 10,
        font: {
          size: 10
        }
      }
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const formatValue = (value) => value != null ? value.toFixed(2) : 'N/A';

          if (context.dataset.label === 'Prédictions historiques') {
            const prediction = context.raw;
            if (!prediction) return 'Données de prédiction non disponibles';
            let status = 'Non évaluée';
            if (prediction.isSuccess === true) status = 'Réussie';
            else if (prediction.isSuccess === false) status = 'Échouée';
            else if (prediction.actualPrice !== null) status = 'En cours d\'évaluation';
            return [
              `Prédit: $${formatValue(prediction.y)}`,
              `Réel: ${prediction.actualPrice != null ? '$' + formatValue(prediction.actualPrice) : 'N/A'}`,
              `Statut: ${status}`,
              `Volatilité: ${prediction.volatility != null ? formatValue(prediction.volatility * 100) + '%' : 'N/A'}`,
              `Intervalle de confiance: ${prediction.confidenceInterval && Array.isArray(prediction.confidenceInterval) && prediction.confidenceInterval.length === 2 
                ? '$' + formatValue(prediction.confidenceInterval[0]) + ' - $' + formatValue(prediction.confidenceInterval[1]) 
                : 'N/A'}`,
              `Tendance: ${prediction.trend || 'N/A'}`
            ];
          }
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y != null) {
            if (context.dataset.yAxisID === 'y') {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            } else {
              label += formatValue(context.parsed.y);
            }
          } else {
            label += 'N/A';
          }
          return label;
        }
      }
    },
    annotation: {
      annotations: {
        ...(showSupport && {
          support: {
            type: 'line',
            yMin: support,
            yMax: support,
            borderColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 2,
            label: {
              content: 'Support',
              enabled: true,
              position: 'start',
              font: {
                size: 10
              }
            },
          },
        }),
        ...(showResistance && {
          resistance: {
            type: 'line',
            yMin: resistance,
            yMax: resistance,
            borderColor: 'rgba(54, 162, 235, 0.5)',
            borderWidth: 2,
            label: {
              content: 'Resistance',
              enabled: true,
              position: 'start',
              font: {
                size: 10
              }
            },
          },
        }),
        ...(showMidRange && {
          midRange: {
            type: 'line',
            yMin: midRange,
            yMax: midRange,
            borderColor: 'rgba(75, 192, 192, 0.5)',
            borderWidth: 2,
            label: {
              content: 'Mid-Range',
              enabled: true,
              position: 'start',
              font: {
                size: 10
              }
            },
          },
        }),
        ...calculateFibonacciLines(),
      },
    },
  },
};
return (
  <Router>
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <header className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md p-2 flex justify-between items-center h-[60px]`}>
        <h1 className="text-xl font-bold">Crypto Range Trading</h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setIsDarkMode(!isDarkMode)}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            {isDarkMode ? '🌞' : '🌙'}
          </button>
          <button
            onClick={() => setShowControls(!showControls)}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            <FaRegCheckSquare />
          </button>
        </div>
        <nav className="hidden sm:block">
          <Link to="/" className="text-blue-500 hover:text-blue-700 mr-4">Accueil</Link>
          <Link to="/history" className="text-blue-500 hover:text-blue-700">Historique</Link>
        </nav>
      </header>

      <Routes>
        <Route path="/" element={
          <main className="flex-grow flex flex-col lg:flex-row overflow-hidden max-w-[2000px] mx-auto">
            <div className="w-full lg:w-[65%] xl:w-[70%] h-full relative">
              {showControls && (
                <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-2 shadow-md z-10 flex flex-wrap items-center justify-between`}>
                  <div className="flex space-x-2 mb-2 sm:mb-0">
                    <select
                      className={`p-1 text-sm border rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                      onChange={(e) => {
                        const newCrypto = e.target.value;
                        console.log(`Changing crypto to: ${newCrypto}`);
                        setSelectedCrypto(newCrypto);
                        fetchData(newCrypto, selectedTimeframe);
                      }}
                      value={selectedCrypto}
                    >
                      <option value="bitcoin">Bitcoin</option>
                      <option value="ethereum">Ethereum</option>
                      <option value="solana">Solana</option>
                      <option value="cosmos">ATOM</option>
                      <option value="zelcash">Flux</option>
                    </select>
                    <select
                      className={`p-1 text-sm border rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                      onChange={(e) => {
                        const newTimeframe = Number(e.target.value);
                        setSelectedTimeframe(newTimeframe);
                        fetchData(selectedCrypto, Number(e.target.value)); 
                      }}
                      value={selectedTimeframe}
                    >
                      {timeframes.map((timeframe) => (
                        <option key={timeframe.value} value={timeframe.value}>
                          {timeframe.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex space-x-2 text-sm">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showSupport}
                        onChange={() => setShowSupport(!showSupport)} 
                        className="mr-1"
                      />
                      <span>Support</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showResistance}
                        onChange={() => setShowResistance(!showResistance)} 
                        className="mr-1"
                      />
                      <span>Resistance</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showMidRange}
                        onChange={() => setShowMidRange(!showMidRange)} 
                        className="mr-1"
                      />
                      <span>Mid-Range</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showMLPrediction}
                        onChange={() => setShowMLPrediction(!showMLPrediction)} 
                        className="mr-1"
                      />
                      <span>Prédiction ML</span>
                    </label>
                  </div>
                  <button
                    className="px-2 py-1 text-sm bg-blue-500 text-white rounded"
                    onClick={handleClearSelection} 
                  >
                    <FaRegCheckSquare className="inline mr-1" /> Désélectionner Fibonacci
                  </button>
                </div>
              )}
              <div className="w-full h-[calc(100vh-60px)] lg:h-[calc(100vh-120px)] chart-container">
                <Line
                  key={`${selectedCrypto}-${selectedTimeframe}`}
                  data={chartData}
                  options={options}
                  onClick={handleChartClick}
                />
              </div>
            </div>
            <div className={`w-full lg:w-[35%] xl:w-[30%] h-[calc(100vh-60px)] lg:h-[calc(100vh-120px)] overflow-y-auto p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
              <div className="max-w-xl mx-auto">
                <Alerts
                  price={cryptoData.data.prices[cryptoData.data.prices.length - 1] ? cryptoData.data.prices[cryptoData.data.prices.length - 1][1] : null}
                  support={support || null}
                  resistance={resistance || null}
                  ma20={cryptoData.data.ma20 && cryptoData.data.ma20.length > 0 ? cryptoData.data.ma20[cryptoData.data.ma20.length - 1] : null}
                  ma50={cryptoData.data.ma50 && cryptoData.data.ma50.length > 0 ? cryptoData.data.ma50[cryptoData.data.ma50.length - 1] : null}
                  rsi6={cryptoData.data.rsi6 && cryptoData.data.rsi6.length > 0 ? cryptoData.data.rsi6[cryptoData.data.rsi6.length - 1] : null}
                  rsi12={cryptoData.data.rsi12 && cryptoData.data.rsi12.length > 0 ? cryptoData.data.rsi12[cryptoData.data.rsi12.length - 1] : null}
                  rsi24={cryptoData.data.rsi24 && cryptoData.data.rsi24.length > 0 ? cryptoData.data.rsi24[cryptoData.data.rsi24.length - 1] : null}
                  volume24h={cryptoData.data.total_volumes && cryptoData.data.total_volumes.length > 0 ? cryptoData.data.total_volumes[cryptoData.data.total_volumes.length - 1][1] : null}
                  previousVolume24h={previousVolume}
                  prediction={cryptoData.data.prediction || null}
                  chopIndex={cryptoData.data.chopIndex && cryptoData.data.chopIndex.length > 0 ? cryptoData.data.chopIndex[cryptoData.data.chopIndex.length - 1] : null}
                  volatility={volatility}
                  predictionSuccessRate={predictionSuccessRate}
                  confidenceInterval={confidenceInterval}
                  trend={trend}
                  timeframe={selectedTimeframe}
                />
                <DynamicAdvice
                  price={cryptoData.data.prices[cryptoData.data.prices.length - 1] ? cryptoData.data.prices[cryptoData.data.prices.length - 1][1] : null}
                  ma20={cryptoData.data.ma20 && cryptoData.data.ma20.length > 0 ? cryptoData.data.ma20[cryptoData.data.ma20.length - 1] : null}
                  ma50={cryptoData.data.ma50 && cryptoData.data.ma50.length > 0 ? cryptoData.data.ma50[cryptoData.data.ma50.length - 1] : null}
                  rsi6={cryptoData.data.rsi6 && cryptoData.data.rsi6.length > 0 ? cryptoData.data.rsi6[cryptoData.data.rsi6.length - 1] : null}
                  rsi12={cryptoData.data.rsi12 && cryptoData.data.rsi12.length > 0 ? cryptoData.data.rsi12[cryptoData.data.rsi12.length - 1] : null}
                  rsi24={cryptoData.data.rsi24 && cryptoData.data.rsi24.length > 0 ? cryptoData.data.rsi24[cryptoData.data.rsi24.length - 1] : null}
                  chopIndex={cryptoData.data.chopIndex && cryptoData.data.chopIndex.length > 0 ? cryptoData.data.chopIndex[cryptoData.data.chopIndex.length - 1] : null}
                  prediction={cryptoData.data.prediction || null}
                  volatility={volatility}
                  predictionSuccessRate={predictionSuccessRate}
                  cryptoId={selectedCrypto}
                  confidenceInterval={confidenceInterval}
                  trend={trend}
                  timeframe={selectedTimeframe}
                />
              </div>
            </div>
          </main>
        } />
        <Route path="/history" element={<HistoryPage />} />
      </Routes>
      
      <nav className="sm:hidden fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-md p-2 flex justify-around">
        <Link to="/" className="text-blue-500 hover:text-blue-700">Accueil</Link>
        <Link to="/history" className="text-blue-500 hover:text-blue-700">Historique</Link>
      </nav>
    </div>
  </Router>
);
}
  
export default App;