import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { BellIcon, TrendingUpIcon, TrendingDownIcon, BrainIcon, ActivityIcon, PercentIcon } from 'lucide-react';

function Alerts({ 
  price, 
  support, 
  resistance, 
  ma20, 
  ma50, 
  rsi6, 
  rsi12, 
  rsi24, 
  volume24h, 
  previousVolume24h, 
  prediction, 
  chopIndex, 
  volatility, 
  predictionSuccessRate,
  confidenceInterval,
  trend,
  timeframe,
  rsiOverbought = 70,
  rsiOversold = 30,
  maCrossoverThreshold = 0.01,
  volumeChangeThreshold = 20,
  supportResistanceThreshold = 2,
  predictionDifferenceThreshold = 5,
  chopIndexHigh = 60,
  chopIndexLow = 40,
  avgVolatility = 0.02
}) {
  const formatNumber = (value) => {
    return typeof value === 'number' ? value.toFixed(2) : 'N/A';
  };

  const generateRSIAlert = () => {
    if (rsi12 > rsiOverbought || rsi24 > rsiOverbought) {
      return (
        <Alert key="rsi-overbought" variant="destructive">
          <AlertTitle>RSI en zone de surachat</AlertTitle>
          <AlertDescription>
            Valeurs actuelles : RSI12 {formatNumber(rsi12)}, RSI24 {formatNumber(rsi24)}
          </AlertDescription>
        </Alert>
      );
    } else if (rsi12 < rsiOversold || rsi24 < rsiOversold) {
      return (
        <Alert key="rsi-oversold" variant="success">
          <AlertTitle>RSI en zone de survente</AlertTitle>
          <AlertDescription>
            Valeurs actuelles : RSI12 {formatNumber(rsi12)}, RSI24 {formatNumber(rsi24)}
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const generateMACrossoverAlert = () => {
    if (ma20 && ma50 && Math.abs(ma20 - ma50) < (price * maCrossoverThreshold)) {
      return (
        <Alert key="ma-crossover" variant="warning">
          <AlertTitle>Croisement MA imminent</AlertTitle>
          <AlertDescription>
            MA20 ({formatNumber(ma20)}) et MA50 ({formatNumber(ma50)}) se rapprochent
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const generateVolumeAlert = () => {
    if (volume24h && previousVolume24h) {
      const volumeChange = ((volume24h - previousVolume24h) / previousVolume24h) * 100;
      if (Math.abs(volumeChange) > volumeChangeThreshold) {
        return (
          <Alert key="volume-change" variant={volumeChange > 0 ? "info" : "warning"}>
            <AlertTitle className="flex items-center">
              {volumeChange > 0 ? <TrendingUpIcon className="mr-2" /> : <TrendingDownIcon className="mr-2" />}
              {volumeChange > 0 ? "Augmentation" : "Baisse"} significative du volume
            </AlertTitle>
            <AlertDescription>
              Le volume a {volumeChange > 0 ? "augmenté" : "baissé"} de {Math.abs(volumeChange).toFixed(2)}% sur les dernières 24 heures.
            </AlertDescription>
          </Alert>
        );
      }
    }
    return null;
  };

  const generateSupportResistanceAlert = () => {
    const alerts = [];
    if (price && support && resistance) {
      const supportDistance = ((price - support) / price) * 100;
      const resistanceDistance = ((resistance - price) / price) * 100;

      if (supportDistance < supportResistanceThreshold) {
        alerts.push(
          <Alert key="near-support" variant="info">
            <AlertTitle>Proche du support</AlertTitle>
            <AlertDescription>
              Le prix actuel ({formatNumber(price)}) est à {supportDistance.toFixed(2)}% du niveau de support ({formatNumber(support)})
            </AlertDescription>
          </Alert>
        );
      }

      if (resistanceDistance < supportResistanceThreshold) {
        alerts.push(
          <Alert key="near-resistance" variant="info">
            <AlertTitle>Proche de la résistance</AlertTitle>
            <AlertDescription>
              Le prix actuel ({formatNumber(price)}) est à {resistanceDistance.toFixed(2)}% du niveau de résistance ({formatNumber(resistance)})
            </AlertDescription>
          </Alert>
        );
      }
    }
    return alerts;
  };

  const generateMLPredictionAlert = () => {
    if (prediction && price) {
      const predictionDifference = ((prediction - price) / price) * 100;
      if (Math.abs(predictionDifference) > predictionDifferenceThreshold) {
        return (
          <Alert key="ml-prediction" variant={predictionDifference > 0 ? "info" : "warning"}>
            <AlertTitle className="flex items-center">
              <BrainIcon className="mr-2" />
              Prédiction ML significative
            </AlertTitle>
            <AlertDescription>
              Le modèle ML prédit un prix de {formatNumber(prediction)} pour les prochains {timeframe} jours, 
              soit une variation de {predictionDifference.toFixed(2)}% 
              par rapport au prix actuel de {formatNumber(price)}. 
              Taux de réussite des prédictions : {formatNumber(predictionSuccessRate)}%
            </AlertDescription>
          </Alert>
        );
      }
    }
    return null;
  };

  const generateChopIndexAlert = () => {
    if (chopIndex) {
      if (chopIndex > chopIndexHigh) {
        return (
          <Alert key="high-chop" variant="warning">
            <AlertTitle className="flex items-center">
              <ActivityIcon className="mr-2" />
              Marché très agité
            </AlertTitle>
            <AlertDescription>
              L'indice Chop est élevé ({formatNumber(chopIndex)}), indiquant un marché sans tendance claire.
            </AlertDescription>
          </Alert>
        );
      } else if (chopIndex < chopIndexLow) {
        return (
          <Alert key="low-chop" variant="info">
            <AlertTitle className="flex items-center">
              <ActivityIcon className="mr-2" />
              Marché tendanciel
            </AlertTitle>
            <AlertDescription>
              L'indice Chop est bas ({formatNumber(chopIndex)}), indiquant un marché avec une tendance forte.
            </AlertDescription>
          </Alert>
        );
      }
    }
    return null;
  };

  const generateVolatilityAlert = () => {
    if (volatility && volatility > avgVolatility * 2) {
      return (
        <Alert key="high-volatility" variant="warning">
          <AlertTitle className="flex items-center">
            <ActivityIcon className="mr-2" />
            Volatilité élevée
          </AlertTitle>
          <AlertDescription>
            La volatilité actuelle ({formatNumber(volatility * 100)}%) est significativement supérieure à la moyenne.
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const generateConfidenceIntervalAlert = () => {
    if (confidenceInterval && price) {
      const [lowerBound, upperBound] = confidenceInterval;
      const range = ((upperBound - lowerBound) / price) * 100;
      if (range > 10) { // Alerte si l'intervalle de confiance est large (plus de 10% du prix actuel)
        return (
          <Alert key="confidence-interval" variant="warning">
            <AlertTitle className="flex items-center">
              <PercentIcon className="mr-2" />
              Large intervalle de confiance
            </AlertTitle>
            <AlertDescription>
              L'intervalle de confiance pour les {timeframe} prochains jours est large ({range.toFixed(2)}% du prix actuel), 
              indiquant une incertitude élevée dans la prédiction.
            </AlertDescription>
          </Alert>
        );
      }
    }
    return null;
  };

  const generateTrendAlert = () => {
    if (trend) {
      return (
        <Alert key="trend" variant={trend === "À la hausse" ? "info" : trend === "À la baisse" ? "warning" : "default"}>
          <AlertTitle className="flex items-center">
            {trend === "À la hausse" ? <TrendingUpIcon className="mr-2" /> : 
             trend === "À la baisse" ? <TrendingDownIcon className="mr-2" /> : 
             <ActivityIcon className="mr-2" />}
            Tendance du marché
          </AlertTitle>
          <AlertDescription>
            La tendance actuelle du marché sur {timeframe} jours est {trend.toLowerCase()}.
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const generateAlerts = () => {
    const alerts = [
      generateRSIAlert(),
      generateMACrossoverAlert(),
      generateVolumeAlert(),
      ...generateSupportResistanceAlert(),
      generateMLPredictionAlert(),
      generateChopIndexAlert(),
      generateVolatilityAlert(),
      generateConfidenceIntervalAlert(),
      generateTrendAlert()
    ].filter(Boolean);

    const alertPriority = {
      "destructive": 1,
      "warning": 2,
      "info": 3,
      "success": 4,
      "default": 5
    };

    alerts.sort((a, b) => alertPriority[a.props.variant] - alertPriority[b.props.variant]);

    return alerts;
  };

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle className="flex items-center text-lg sm:text-xl">
          <BellIcon className="mr-2" /> Alertes
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {generateAlerts().length > 0 ? generateAlerts().map((alert, index) => (
          <div key={index} className="text-sm sm:text-base">
            {React.cloneElement(alert, {
              className: `${alert.props.className || ''} p-2 sm:p-4`,
              children: React.Children.map(alert.props.children, child => {
                if (child.type === AlertTitle) {
                  return React.cloneElement(child, {
                    className: `${child.props.className || ''} text-sm sm:text-base font-semibold`
                  });
                }
                if (child.type === AlertDescription) {
                  return React.cloneElement(child, {
                    className: `${child.props.className || ''} text-xs sm:text-sm mt-1`
                  });
                }
                return child;
              })
            })}
          </div>
        )) : (
          <p className="text-sm sm:text-base">Aucune alerte active pour le moment.</p>
        )}
      </CardContent>
    </Card>
  );
}

Alerts.propTypes = {
  price: PropTypes.number,
  support: PropTypes.number,
  resistance: PropTypes.number,
  ma20: PropTypes.number,
  ma50: PropTypes.number,
  rsi6: PropTypes.number,
  rsi12: PropTypes.number,
  rsi24: PropTypes.number,
  volume24h: PropTypes.number,
  previousVolume24h: PropTypes.number,
  prediction: PropTypes.number,
  chopIndex: PropTypes.number,
  volatility: PropTypes.number,
  predictionSuccessRate: PropTypes.number,
  confidenceInterval: PropTypes.arrayOf(PropTypes.number),
  trend: PropTypes.string,
  timeframe: PropTypes.number,
  rsiOverbought: PropTypes.number,
  rsiOversold: PropTypes.number,
  maCrossoverThreshold: PropTypes.number,
  volumeChangeThreshold: PropTypes.number,
  supportResistanceThreshold: PropTypes.number,
  predictionDifferenceThreshold: PropTypes.number,
  chopIndexHigh: PropTypes.number,
  chopIndexLow: PropTypes.number,
  avgVolatility: PropTypes.number
};

export default Alerts;